/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

#root {
    height: 100%;
}

body{
    background-color: var(--surface-ground);
    font-family: var(--font-family);
    color: var(--text-color);
    font-size: 13px;
    margin: 0;
    height:100%;
}

html {
    height:100%
}

#app {
    padding: 0.5em;
}
